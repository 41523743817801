import React, { Fragment, Component } from 'react';
import '../Batiments.css';
import { Helmet } from 'react-helmet';

class Batiment extends Component {
        
        componentDidMount = () =>{
                this.props.photo(4);
                document.getElementById('presentation').setAttribute('style','display:none;');
                document.getElementById('Facades').setAttribute('style','display:none;');
                document.getElementById('Metal').setAttribute('style','display:none;');
                document.getElementById('References').setAttribute('style','display:none;');
                document.getElementById('Contact').setAttribute('style','display:none;');
                document.getElementById('Legalo').setAttribute('style','display:none;');
                document.getElementById('Batiments').setAttribute('style','display:block;');
                document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg');");
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg");');
                }
               
        }

        render() { 
                return (
                        <Fragment>
                                 <Helmet>
                                        <title>SFM, pour la fermeture de vos bâtiments, à Viry-Châtillon et Brie-Comte-Robert</title>
                                        <meta name="description"content="Notre société SFM, en plus de réaliser vos devantures de magasins et vos ouvrages métalliques sur mesure, vous propose également de poser vos fermetures de bâtiments."/>
                                </Helmet>
                                <div id="notre">
                                        <p>Notre société SFM, en plus de réaliser vos devantures de magasins et vos ouvrages métalliques sur mesure, vous propose également de poser vos fermetures de bâtiments.</p>
                                        <p>Afin de vous apporter toujours plus de sécurité, nous mettons à votre disposition plusieurs modèles de portes blindées et de portes métalliques, que nous concevons sur mesure.</p>
                                        <p>Nous nous déplaçons dans les départements de l’Essonne, de la Seine-et-Marne et sur toute la région Île-de-France.</p>
                                        <p>Nous installons également vos ouvertures de portes et vos serrures ; nous vous dépannons 24h/24 et 7j/7.</p>
                                </div>
                                <section>
                                        <h2>Nos portes blindées métalliques</h2>
                                        <div id="blinde">
                                                <div>
                                                        <p>Nos portes blindées en acier sont robustes et résistent aux infractions. Elles sont composées d’une mousse polyuréthane, insérée entre les deux panneaux de la porte et d’un joint d’étanchéité. Les propriétés thermiques et isolantes de vos portes sont renforcées.</p>
                                                        <p>Ces dernières sont également traitées contre la corrosion ; elles sont disponibles en plusieurs couleurs et peuvent se repeindre au besoin. Elles sont équipées de serrures, comportant 3 ou 4 points de fermeture, que nous installons également.</p>
                                                        <p>Elles ont adaptées aux personnes à mobilité réduite, par l’ajout d’un seuil supplémentaire en aluminium. Vous travaillez pour une collectivité ou un syndicat de copropriété ? Nous concevons vos portes d’immeubles sur mesure, entièrement sécurisées.</p>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                        <div id="serrure">
                                                <br/>
                                                <h3>Comment choisir sa serrure ?</h3>
                                                <p>Nos serrures portent toutes le label A2P (Assurance Prévention Protection) ; ce label est gage de qualité et de protection optimale de vos biens, contre les instruisions et les incendies.</p>
                                                <p>Elles sont classées par étoiles, selon leur durée moyenne de résistance aux infractions. Toutes nos serrures se déclinent en 1 ou plusieurs points d’ancrage (nombre de systèmes de fermetures intégrés).</p>
                                                <p>À trois points par exemple, elle se ferme en bas, en haut et au milieu de la porte. Pour bien choisir sa serrure, il est nécessaire de définir l’usage de la serrure, le niveau de sécurité voulu et l’épaisseur de la porte.</p>
                                                <br/>
                                        </div>
                                        <div id="modele">
                                        <br/>
                                                <h3>Nos modèles de serrures</h3>
                                                <p>Nous vous proposons :</p>
                                                <ul>
                                                        <li>des serrures à poignée, qui vous permettent d’actionner le mécanisme de la porte,</li>
                                                        <li>des serrures en applique, qui s’adaptent aux grilles ou aux portes de garage ; elles sont composées d’un boitier apparent en métal,</li>
                                                        <li>des serrures à encastrer, qui sont fixées dans l’épaisseur de la porte,</li>
                                                        <li>des serrures carénées, renforcées par une poutre verticale, équipées d’une poignée et d’un crochet ; elles s’installent sur les bâtiments industriels ou les portes de pavillon.</li>
                                                </ul>
                                                <p>Elles se verrouillent : au moyen d’une clé, d’une carte, d’un code, biométrique ou d’un système anti-panique : ce dernier système est particulièrement adapté aux locaux accueillant du public, qui disposent d’une sortie de secours. Les portes sont équipées d’une barre.</p>
                                                <br/>
                                        </div>
                                </section>
                                <section>
                                        <h2>Le dépannage de votre serrurerie, 24h/24, sur toute l’Île-de-France</h2>
                                        <div id="urgence">
                                                <div>
                                                        <p>Vous vous êtes enfermé à l’extérieur ? Vous avez perdu vos clés ou vous vous êtes fait voler vos affaires ?</p>
                                                        <p>Faites appel à SFM ! Nous réalisons toutes les ouvertures de portes ; en cas d’effraction, nous remplaçons vos équipements.</p>
                                                        <p>Nous assurons la pose, le dépannage et la réparation de vos serrures.</p>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/urgence-deux-tel.png'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                </section>
                        </Fragment>
                  );
        }
}
 
export default Batiment;