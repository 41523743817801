import React, { Fragment,Component } from 'react';
import{BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../Metal.css';

class Metal extends Component {
        
        componentDidMount = () =>{
                this.props.photo(2);
                document.getElementById('presentation').setAttribute('style','display:none;');
                document.getElementById('Facades').setAttribute('style','display:none;');
                document.getElementById('Batiments').setAttribute('style','display:none;');
                document.getElementById('References').setAttribute('style','display:none;');
                document.getElementById('Contact').setAttribute('style','display:none;');
                document.getElementById('Legalo').setAttribute('style','display:none;');
                document.getElementById('Metal').setAttribute('style','display:block;');
                document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg');");
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg");');
                }
        }

        render() { 
                return (
                        <Fragment>
                                 <Helmet>
                                        <title>SFM, Nos travaux de métallerie sur mesure, de Viry-Châtillon à Brie-Comte-Robert</title>
                                        <meta name="description"content="Notre entreprise SFM, c’est aussi la conception, la fabrication, la pose et la manutention de vos ouvrages métalliques sur mesure."/>
                                </Helmet>
                        <div id="aussi">
                                <p>Notre entreprise SFM, c’est aussi <b>la conception, la fabrication, la pose et la manutention</b> de vos ouvrages métalliques sur mesure.</p>
                                <p>Nous mettons un point d’honneur à sélectionner les meilleurs matériaux : toutes nos réalisations se déclinent en plusieurs coloris (par thermolaquage) et profils. Ainsi, elles s’harmonisent avec vos menuiseries extérieures (fenêtres, volets roulants, portes…). Nous réalisons également les <span>devantures de vos magasins</span>  et de vos locaux professionnels.</p>
                                <p>Envie de plus de sécurité ? Découvrez <span>nos portes blindées et nos modèles de serrures</span>. Nous nous déplaçons <b>dans toute la région Île-de-France</b>  pour réaliser des études de faisabilité.</p>
                                <p>Nos devis sont gratuits et sans engagement ; ils vous sont délivrés rapidement.</p>
                        </div>
                        <section>
                                <h2>Nos partenaires</h2>
                                <div id="partenaires">
                                        <div>
                                                <p>Nous réalisons des travaux <b>en neuf comme en rénovation</b> ; notre clientèle éclectique nous permet de diversifier nos pratiques et nos produits.</p>
                                                <p>Quelques exemples de partenaires :</p>
                                                <ul>
                                                        <li>architectes,</li>
                                                        <li>magasins d’optique,</li>
                                                        <li>pharmacies,</li>
                                                        <li>musées,</li>
                                                        <li><b>collectivités,</b></li>
                                                        <li><b>copropriétés,</b></li>
                                                        <li>cinémas,</li>
                                                        <li>librairies,</li>
                                                        <li>magasins de prêt-à-porter…</li>
                                                </ul>
                                                <br/><br/><br/>
                                                
                                                <button><Link href="#Refenrences" to="/Nos_références" hrefLang="fr">Nos références</Link></button>
                                                <br/>
                                                <br/>
                                        </div>
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                </div>
                                <div id="inox">
                                        <br/>
                                        <p>L’inox présente de nombreux avantages :</p>
                                        <ul>
                                                <li> sa structure est légère et fine, il est facilement modulable,</li>
                                                <li>son aspect est lisse et brillant, ce qui apporte une certaine luminosité à la pièce,</li>
                                                <li>il résiste parfaitement aux intempéries et à la corrosion,</li>
                                                <li>son montage peut être vissé ou soudé, ce qui apporte une meilleure résistance aux vibrations,</li>
                                                <li>il peut être commandé avec l’option anti-rayure, ce qui facilite son entretien.</li>
                                        </ul>
                                        <br/>
                                </div>
                                <div id="acier">
                                        <br/>
                                        <p>Patiné, galvanisé, peint ou brut, l’acier est facilement personnalisable. Il se décline en plus de 1200 couleurs.</p>
                                        <ul>
                                                <li>en acier brut, votre escalier est de style contemporain : il peut être ciré, vernis ou lasuré,</li>
                                                <li>en acier peint, il est traité contre la corrosion : son aspect est mat, brillant ou sablé,</li>
                                                <li>en acier galvanisé, il est protégé contre la rouille et l’abrasion au moyen d’un traitement à base de zinc,</li>
                                                <li>en acier thermolaqué, il est composé d’une finition sablée par-dessus laquelle une résine thermoducie est appliquée ; il est satiné ou brillant.</li>
                                        </ul>
                                        <br/>
                                </div>
                        </section>
                        <section>
                                <h2>Nos escaliers métalliques sur mesure</h2>
                                <div id="escaliers">
                                        <div id="escaliers2">
                                        <div>
                                                <p>Durables et résistants, nos escaliers en métal s’allient facilement avec d’autres matériaux, comme le verre ou le bois.</p>
                                                <p>Plus légers que le béton, ils s’intègrent aux constructions et aux agencements extérieurs et intérieurs.</p>
                                                <p>Personnalisables facilement, ils peuvent se peindre et sont disponibles en plusieurs teintes.</p>
                                                <p>Ils se déclinent en inox, en aluminium ou en acier.</p>
                                        </div>
                                        <div>
                                                <h3>Nos escaliers hélicoïdaux</h3>
                                                <p>Nos escaliers hélicoïdaux s’installent facilement dans tous les intérieurs.</p>
                                                <p>Il présente de nombreux avantages, notamment celui d’encombrer faiblement l’espace.</p>
                                                <p>Contemporains, modernes et design, ils sont élégants et solides.</p>
                                        </div>
                                        </div>
                                        
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                </div>
                        </section>
                        <section>
                                <h2>Nos passerelles ou mezzanines métalliques</h2>
                                <div id="mezza">
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                        <div>
                                                <p>En aluminium ou en acier, nos passerelles métalliques apportent un espace supplémentaire à votre habitat.</p>
                                                <p>À l’intérieur, elles peuvent se transformer en chambre, en bureau.</p>
                                                <p>À l’extérieur, elles sont utilisées comme accès privilégié pour les agents de lutte contre l’incendie ou les secours.</p>
                                                <p>Légères et résistantes, elles sont conçues pour supporter des charges importantes. Elles se mixent facilement avec le verre ou le bois, pour apporter une touche esthétique supplémentaire. Elles se peignent de la couleur que vous désirez.</p>
                                        </div>
                                </div>
                        </section>
                </Fragment>
                  );
        }
}
 
export default Metal;
