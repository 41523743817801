import React, { Fragment, Component } from 'react';
import '../Contact.css';
import { Helmet } from 'react-helmet';

class Contact extends Component {
       
        state = {
                personne :{
                        nom: "",
                        prenom:"",
                        mail:"",
                        tel:"",
                        message:""
                }
        }

        componentDidMount = () =>{
                this.props.photo(6);
                document.getElementById('Metal').setAttribute('style','display:none;');
                document.getElementById('Facades').setAttribute('style','display:none;');
                document.getElementById('Batiments').setAttribute('style','display:none;');
                document.getElementById('presentation').setAttribute('style','display:none;');
                document.getElementById('References').setAttribute('style','display:none;');
                document.getElementById('Legalo').setAttribute('style','display:none;');
                document.getElementById('Contact').setAttribute('style','display:block;');
                document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/IMG_20210117_161631.jpg');"); 
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important; background-image: url("./images/IMG_20210117_161631.jpg");');
                }
        }

        nom = (e) =>{
                const newPersonne = this.state.personne;
                newPersonne.nom = e.target.value;
                this.setState({ newPersonne });
        }

        prenom = (e) =>{
                const newPersonne = this.state.personne;
                newPersonne.prenom = e.target.value;
                this.setState({ newPersonne });
        }

        mail = (e) =>{
                const newPersonne = this.state.personne;
                newPersonne.mail = e.target.value;
                this.setState({ newPersonne });
        }

        tel = (e) =>{
                const newPersonne = this.state.personne;
                newPersonne.tel = e.target.value;
                this.setState({ newPersonne });
        }
        msg = (e) =>{
                const newPersonne = this.state.personne;
                newPersonne.message = e.target.value;
                this.setState({ newPersonne });
        }

        recup = (e) =>{
                e.preventDefault();
                if(document.getElementById('nom').value.length === 0){
                        alert('Veuillez saisir votre nom');
                }else if(document.getElementById('prenom').value.length === 0){
                        alert('Veuillez saisir votre prenom');
                }else if(document.getElementById('mail').value.length === 0){
                        alert('Veuillez saisir votre adresse email');
                }else if(document.getElementById('tel').value.length === 0){
                        alert('Veuillez saisir votre numéro de téléphonel');
                }else if(document.getElementById('msg').value.length <10){
                        alert('Veuillez saisir au moins 10 caractères');
                }else if(document.getElementById('data1').checked == false){
                        alert('Veuillez accepter notre politique de confidentialité');
                }else{
                        fetch('https://www.sfm-serrurerie.fr/BackEnd/index.php', {
                                method: 'POST',
                                data:JSON.stringify(this.state.newPersonne),
                                body: JSON.stringify(this.state.newPersonne),
                        }).then(res => {
                                alert('Votre message a été envoyé');
                                console.log('Votre message a bien été envoyé')
                        }).catch(err => {
                                alert('Echec serveur');
                        });
                }               
        }

        render() { 
                return ( 
                        <Fragment>
                                 <Helmet>
                                        <title>SFM, Nous Contacter</title>
                                        <meta name="description"content="Notre société SFM est spécialisée dans la conception, la fabrication, le montage et la pose de vos menuiseries, miroiteries et de vos ouvrages métalliques sur mesure."/>
                                </Helmet>
                                <div id="nousContacter">
                                        <div>
                                                <h3>SFM Viry-Châtillon</h3>
                                                <ul>
                                                        <li><i className="fas fa-map-marker-alt"></i>&nbsp;105/117 avenue Victor Schoelcher 91170 Viry-Châtillon</li>
                                                        <li><i className="fas fa-phone"></i>&nbsp;&nbsp;<a href="tel:+33169240381">01 69 24 03 81</a></li>
                                                        <li><i className="fas fa-fax"></i>&nbsp;&nbsp;01 69 24 03 29</li>
                                                </ul>
                                        </div>
                                        <div>
                                                <h3>SFM Brie Comte Robert</h3>
                                                <ul>
                                                        <li><i className="fas fa-map-marker-alt"></i>&nbsp;45 rue Général Leclerc 77170 Brie Comte Robert</li>
                                                        <li><i className="fas fa-phone"></i>&nbsp;&nbsp;<a href="tel:+33160296022">01 60 29 60 22</a></li>
                                                </ul>
                                        </div>
                                </div>
                                <section>
                                        <h2>Nous localiser</h2>
                                        <div><iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=105%20avenue%20Victor%20Schoelcher%2091170%20Viry-Ch%C3%A2tillon+(SFM,%20serrurerie,%20menuiserie%20et%20miroiterie,%20%C3%A0%20Viry-Ch%C3%A2tillon%20et%20Brie-Comte-Robertiness%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
                                </section>
                                <section>
                                        <h2>Nous contacter</h2>
                                        <div id="formulaire">
                                                <div id="a">
                                                        <br/><br/>
                                                        <img src={process.env.PUBLIC_URL+'/images/logo.svg'} alt="Logo de l'entreprise"/>
                                                        <p>Notre société SFM est spécialisée dans la conception, la fabrication, le montage et la pose de vos menuiseries, miroiteries et de vos ouvrages métalliques sur mesure.</p>
                                                        <p>Nous vous conseillons pour la réalisation et l’agencement de vos façades ou de vos fermetures de magasin. Nous réalisons des travaux en neuf comme en rénovation ; notre service après-vente est à votre disposition pour tous problèmes sur vos équipements.</p>
                                                        <p>Nous nous déplaçons pour une étude détaillée de vos projets ; nos devis sont gratuits et détaillés.</p>
                                                        <p>Nous travaillons en partenariat avec les particuliers et pour les professionnels de la région : les collectivités, les copropriétés, les administrations ou de grandes marques connues font partie de notre clientèle régulière.</p>
                                                </div>
                                                <div id="b">
                                                <form method="post" action="" autoComplete="on" onSubmit={this.recup}>
                                                        <div>
                                                                <label htmlFor="nom">Nom :</label>
                                                                <input id="nom" name="nom" type="text" onChange={this.nom}/>
                                                        </div>
                                                        <div>
                                                                <label htmlFor="prenom">Prénom :</label>
                                                                <input id="prenom" name="prenom" type="text" onChange={this.prenom}/>
                                                        </div>
                                                        <div>
                                                                <label htmlFor="mail">Adresse email :</label>
                                                                <input id="mail" name="mail" type="email" onChange={this.mail}/>
                                                        </div>
                                                        <div>
                                                                <label htmlFor="tel">Téléphone :</label>
                                                                <input id="tel" name="tel" type="tel" onChange={this.tel}/>
                                                        </div>
                                                        <div>
                                                                <label htmlFor="msg">Votre message :</label>
                                                                <textarea id="msg" name="msg" cols="30" rows="10" onChange={this.msg}></textarea>
                                                        </div>
                                                        <br/>
                                                        <div id="data">
                                                                <input type="checkbox" name="data" id="data1"/> <span id="dataSpan">J’ai pris connaissance de la manière dont mes données sont traitées et j’accepte la politique de protection de la vie privée du site</span>
                                                        </div>
                                                        <br/>
                                                        <div id="boutons">
                                                                <button type="reset">Reset</button>&nbsp;<button type="submit" id="valid">Valider</button>
                                                        </div>
                                                </form>
                                                </div>
                                        </div>
                                </section>
                        </Fragment>
                 );
        }
}
 
export default Contact;