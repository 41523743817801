import React, { Fragment, Component } from 'react';
import{BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import '../References.css';
import { Helmet } from 'react-helmet';

class References extends Component {
        
        componentDidMount = () =>{
                this.props.photo(5);
                document.getElementById('Metal').setAttribute('style','display:none;');
                document.getElementById('Facades').setAttribute('style','display:none;');
                document.getElementById('Batiments').setAttribute('style','display:none;');
                document.getElementById('presentation').setAttribute('style','display:none;');
                document.getElementById('Contact').setAttribute('style','display:none;');
                document.getElementById('Legalo').setAttribute('style','display:none;');
                document.getElementById('References').setAttribute('style','display:block;');
                document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/eyeemfiltered1579424914184.jpg');");   
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg");');
                }
        }

        eiffage = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/eiffage.jpg');
        }
        spie = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/spie.jpg');
        }
        picard = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/picard.jpg');
        }
        murs = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/4murs.jpg');
        }
        courtepaille = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/courtepaille.jpg');
        }
        renault = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/renault.jpg');
        }
        total = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/total.jpg');
        }
        lcl = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/lcl.jpg');
        }
        bnp = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/bnp.jpg');
        }
        flunch = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/flunch.jpg');
        }
        ollygan = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/ollygan.jpg');
        }
        camaieu = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/camaieu.jpg');
        }
        okaidi = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/okaidi.jpg');
        }
        jacadi = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/jacadi.jpeg');
        }
        normal = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/eyeemfiltered1579424914184.jpg');
        }
        jules = () =>{
                document.getElementById('entreprise').setAttribute('src','./images/jules.jpg');
        }

        render() { 
                return ( 
                        <Fragment>
                                <Helmet>
                                        <title>Quelques partenaire de SFM, sur toute la région Île-de-France</title>
                                        <meta name="description"content="Notre siège social se situe à Viry-Châtillon, dans le 91, nous disposons également d’une autre antenne à Brie-Comte-Robert, dans le 77."/>
                                </Helmet>
                                <div id="siege">
                                        <p>Notre siège social se situe à Viry-Châtillon, dans le 91, nous disposons également d’une autre antenne à Brie-Comte-Robert, dans le 77.</p>
                                        <p>Nos engagements :</p>
                                        <ul>
                                                <li>vous remettre un devis détaillé et personnalisé sous 5 jours,</li>
                                                <li>commencer les travaux à la date fixée avec vous,</li>
                                                <li>especter les délais de production prévus,</li>
                                                <li>ne percevoir le solde qu’après réception formelle des travaux.ne percevoir le solde qu’après réception formelle des travaux.</li>
                                        </ul>
                                        <p>Nous réalisons les <Link href="#Facades" to="/Façades_de_magasins" >agencements de vos magasins</Link>, <Link href="#Metal" to="/Travaux_de_métallerie" >vos travaux de métallerie sur mesure</Link> et <Link href="#Batiments" to="/Fermeture_de_bâtiments" >vos fermetures de bâtiments</Link>.</p>
                                </div>
                                <section>
                                        <h2>Les administrations</h2>
                                        <div id="admin">
                                                <div>
                                                        <ul>
                                                                <li>Bases aériennes</li>
                                                                <li>Camps militaires</li>
                                                                <li>Ministère de la Défense </li>
                                                                <li>Mairies</li>
                                                                <li>Lycées</li>
                                                        </ul>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                </section>
                                <section>
                                        <h2>Les commerces et les entreprises</h2>
                                        <div id="commerces">
                                                <div>
                                                        <ul>
                                                                <li><a target="blank" href="https://www.eiffage.com/" onMouseOver={this.eiffage} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Eiffage industrie</a></li>
                                                                <li><a target="blank" href="https://www.spie.com/fr" onMouseOver={this.spie} onMouseLeave={this.normal}  hrefLang="fr" rel="external">SPIE</a></li>
                                                                <li><a target="blank" href="https://www.picard.fr/" onMouseOver={this.picard} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Picard Surgelés IDF</a></li>
                                                                <li><a target="blank" href="https://www.4murs.com/" onMouseOver={this.murs} onMouseLeave={this.normal}  hrefLang="fr" rel="external">4 Murs IDF</a></li>
                                                                <li><a target="blank" href="https://www.courtepaille.com/" onMouseOver={this.courtepaille} onMouseLeave={this.normal} hrefLang="fr" rel="external">Courtepaille IDF</a></li>
                                                                <li><a target="blank" href="https://www.renaultsport.com/" onMouseOver={this.renault} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Renault Sport F1</a></li>
                                                                <li><a target="blank" href="https://www.total.com/fr" onMouseOver={this.total}  onMouseLeave={this.normal}  hrefLang="fr" rel="external">TOTAL</a></li>
                                                                <li><a target="blank" href="https://particuliers.secure.lcl.fr/index.html" onMouseOver={this.lcl} onMouseLeave={this.normal}  hrefLang="fr" rel="external">LCL</a></li>
                                                                <li><a target="blank" href="https://mabanque.bnpparibas/" onMouseOver={this.bnp} onMouseLeave={this.normal}  hrefLang="fr" rel="external">BNP Parisbas</a></li>
                                                                <li><a target="blank" href="https://www.flunch.fr/"  onMouseOver={this.flunch}  onMouseLeave={this.normal}  hrefLang="fr">Flunch</a></li>
                                                                <li><a target="blank" href="https://www.ollygan.com/fr/"  onMouseOver={this.ollygan} onMouseLeave={this.normal}  hrefLang="fr" rel="external">OLLYGAN</a></li>
                                                                <li><a target="blank" href="https://www.camaieu.fr/" onMouseOver={this.camaieu} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Camaieu</a></li>
                                                                <li><a target="blank" href="https://www.jules.com/fr-fr/index/" onMouseOver={this.jules} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Jules</a></li>
                                                                <li><a target="blank" href="https://www.okaidi.fr/" onMouseOver={this.okaidi} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Okaidi</a></li>
                                                                <li><a target="blank" href="https://www.jacadi.fr/" onMouseOver={this.jacadi} onMouseLeave={this.normal}  hrefLang="fr" rel="external">Jacadi</a></li>
                                                        </ul>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise" id="entreprise"/> 
                                                </div>
                                        </div>
                                        <div id="assurances1">
                                                <br/>
                                                <h3>Les administrations de biens</h3>
                                                <ul>
                                                        <li><a target="blank" href="https://www.laforet.com/" rel="external">La Forêt</a></li>
                                                        <li><a target="blank" href="https://www.ladresse.com/" rel="external" hrefLang="fr">L'Adresse immobilier</a></li>
                                                        <li><a target="blank" href="http://www.fontenoy.com/" rel="external" hrefLang="fr">Fontenoy Immobilier</a></li>
                                                        <li><a target="blank" href="https://tmh.polylogis.immo/" rel="external" hrefLang="fr">Trois Moulins Habitat</a></li>
                                                        <li><a target="blank" href="https://fr.foncia.com/" rel="external" hrefLang="fr">Foncia</a></li>
                                                        <li>&nbsp;</li>
                                                        <li>Syndicats de copropriétés</li>                                                        
                                                </ul>
                                                <br/>
                                        </div>
                                        <div id="assurances2">
                                                <br/>
                                                <h3>Les compagnies d’assurance</h3>
                                                <br/>
                                                <ul>
                                                        <li><a target="blank" href="https://www.generali.fr/" rel="external" hrefLang="fr">Generali Assurance</a></li>
                                                        <li><a target="blank" href="https://www.europ-assistance.fr/" rel="external" hrefLang="fr">Europ Assistance</a></li>
                                                        <li><a target="blank" href="https://www.axa.fr/" rel="external" hrefLang="fr">Axa</a></li>
                                                </ul>
                                                <br/>
                                                <br/>
                                                <br/>
                                        </div>
                                </section>
                        </Fragment>
                 );
        }
}
 
export default References;