import './Header.css';
import './Footer.css';
import'./Pad.css';
import Header from './Components/Header';

function App() {
  return (
    <div className="App">
      <Header />
    </div>
  );
}

export default App;
