import React, { Component} from 'react';
import{BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import Acceuil from './Acceuil';
import Metal from './Metal';
import Facades from './Facades';
import Batiments from './Batiments';
import References from './References';
import Contact from './Contact';
import Legal from './Legal';
import Footer from './Footer';

class Header extends Component {

        state = {photo:0}

        componentDidMount = () =>{
                console.clear();
                console.log('Bienvenue sur https://www.sfm-serrurerie.fr/');
        }

        handleCallback = (data) =>{
                this.setState({photo:data})
        }

        mobileMenu = () => {
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementById('padNavig').setAttribute('style','display:none');
                        if(this.state.photo == 1){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg") !important;');
                        }else if(this.state.photo == 2){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                        else if(this.state.photo == 3){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/IMG_20210117_161631.jpg") !important;');
                        }
                        else if(this.state.photo == 4){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                        else if(this.state.photo == 5){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg") !important;');
                        }else if(this.state.photo == 6){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/IMG_20210117_161631.jpg") !important;');
                        }else if(this.state.photo == 7){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:800px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                }else{
                        document.getElementById('padNavig').setAttribute('style','display:block;');
                        if(this.state.photo == 1){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg") !important;');
                        }else if(this.state.photo == 2){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                        else if(this.state.photo == 3){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/IMG_20210117_161631.jpg") !important;');
                        }
                        else if(this.state.photo == 4){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                        else if(this.state.photo == 5){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg") !important;');
                        }else if(this.state.photo == 6){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/IMG_20210117_161631.jpg") !important;');
                        }else if(this.state.photo == 7){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg") !important;');
                        }
                }
        }

        up = () =>{
                window.scrollTo(0,0);
        }

        render() { 
                
                return ( 
                        <Router>
                                <header>
                                        <img id="arrow" src={process.env.PUBLIC_URL+'/images/arrow.svg'} alt="Flèche de navigation" onClick={this.up}/>
                                        <br id="espace"/>
                                        <div id="banniere">
                                                <div id="ban1"></div>
                                                <div id="ban2">
                                                        <div>
                                                                <img src={process.env.PUBLIC_URL+'/images/menu.svg'} alt="Menu mobile" id="padLegal" onClick={this.mobileMenu}/>
                                                                <h2>SFM, au service des particuliers et des professionnels depuis 2003</h2>
                                                        </div>
                                                </div>
                                        </div>
                                        <div id='padNavig'>
                                                <ul>
                                                        <li><Link href="#Acceuil" to="/" hrefLang="fr">Acceuil</Link></li>
                                                        <li><Link href="#Metal" to="/Travaux-de-métallerie" hrefLang="fr">Travaux de métallerie</Link></li>
                                                        <li><Link href="#Facades" to="/Façades-de-magasins" hrefLang="fr">Façades de magasins</Link></li>
                                                        <li><Link href="#Batiments" to="/Fermetures-de-bâtiments" hrefLang="fr">Fermetures de bâtiments</Link></li>
                                                        <li><Link href="#Refenrences" to="/Nos-références" hrefLang="fr">Nos références</Link></li>
                                                        <li><Link href="#Contact" to="/Nous-contacter" hrefLang="fr">Contact</Link></li>
                                                </ul>
                                        </div>
                                        <div id="padHeader">
                                                <a href="https://www.sfm-serrurerie.fr/" rel="nofollow" hrefLang="fr"><img src={process.env.PUBLIC_URL+'/images/logo.svg'} alt="Logo de l'entreprise" id="Legal"/></a>
                                        </div>
                                        <div id="menu">
                                                <div id="logo">
                                                        <a href="https://www.sfm-serrurerie.fr/" hrefLang="fr" rel="nofollow"><img src={process.env.PUBLIC_URL+'/images/logo.svg'} alt="Logo de l'entreprise" id="Legal"/></a>
                                                </div>
                                                <div id="navig">
                                                        <nav>
                                                                <ul>
                                                                        <li><Link href="#Acceuil" to="/"   hrefLang="fr">Acceuil</Link></li><span>|</span>
                                                                        <li><Link href="#Metal" to="/Travaux-de-métallerie"  hrefLang="fr">Travaux de métallerie</Link></li><span>|</span>
                                                                        <li><Link href="#Facades" to="/Façades-de-magasins"  hrefLang="fr">Façades de magasins</Link></li><span>|</span>
                                                                        <li><Link href="#Batiments" to="/Fermetures-de-bâtiments"  hrefLang="fr">Fermetures de bâtiments</Link></li><span>|</span>
                                                                        <li><Link href="#References" to="/Nos-références"  hrefLang="fr">Nos références</Link></li><span>|</span>
                                                                        <li><Link href="#Contact" to="/Nous-contacter"  hrefLang="fr">Contact</Link></li>
                                                                        <li style={{display:"none"}}><Link href="#Legal" to="/Mentions-légales" rel="licence"  hrefLang="fr">Mentions légales</Link></li>
                                                                </ul>
                                                        </nav>
                                                </div>
                                        </div>
                                        <div id="presentation">
                                                <div>
                                                        <p>Nous sommes membres de la Fédération Française du Bâtiment.</p>
                                                        <img src={process.env.PUBLIC_URL+'/images/SG_LOC_Header_Left_Logo.png'} alt="Logo de la Fédération Française du Batiment"/>
                                                        <p>Nos devis sont gratuits et sans engagement ; ils vous sont délivrés rapidement, dans un délai maximum de 5 jours.</p>
                                                        <p>Une permanence téléphonique est à votre disposition 24h/24 et 7j/7.</p>
                                                </div>
                                        </div>
                                        <div id="Metal">
                                                <h2>Nos travaux de métallerie sur mesure, de Viry-Châtillon à Brie-Comte-Robert</h2>
                                        </div>
                                        <div id="Facades">
                                                <h2>SFM, vos façades de magasin sur mesure, à Viry-Châtillon et Brie-Comte-Robert</h2>
                                        </div>
                                        <div id="Batiments">
                                                <h2>SFM, pour la fermeture de vos bâtiments, à Viry-Châtillon et Brie-Comte-Robert</h2>
                                        </div>
                                        <div id="References">
                                                <h2>Quelques partenaire de SFM, sur toute la région Île-de-France<br/><img src={process.env.PUBLIC_URL+'/images/republique.jpg'} alt="Logo de la république"/></h2>
                                        </div>
                                        <div id="Contact">
                                        <img src={process.env.PUBLIC_URL+'/images/SG_LOC_Header_Left_Logo.png'} alt="Logo de la Fédération Française du Batiment" width="100"/>
                                                <p>Parce que la qualité est le gage de notre professionnalisme, toute l’équipe de SFM est bercée par les valeurs et les pratiques suivantes :</p>
                                                <ul>
                                                        <li>réactivité,</li>
                                                        <li>disponibilité,</li>
                                                        <li>conseils personnalisés,</li>
                                                        <li>devis précis et fiables,</li>
                                                        <li>finitions et travail soigné,</li>
                                                        <li>service après-vente.</li>
                                                </ul>
                                        </div>
                                        <div id="Legalo">
                                                <h2>Nos Mentions Légales et Politique de Confidentialité</h2>
                                        </div>
                                </header>
                                <main>
                                <Switch>
                                        <Route exact path="/">
                                                <Acceuil photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Travaux-de-métallerie" >
                                                <Metal photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Façades-de-magasins" >
                                                <Facades  photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Fermetures-de-bâtiments" >
                                                <Batiments  photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Nos-références" >
                                                <References  photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Nous-contacter" >
                                                <Contact  photo={this.handleCallback}/>
                                        </Route>
                                        <Route exact path="/Mentions-légales" >
                                                <Legal  photo={this.handleCallback}/>
                                        </Route>
                                        <Route path="/*">
                                                <Acceuil  photo={this.handleCallback}/>
                                        </Route>
                        </Switch>
                                </main>
                        <Footer />
                        </Router>
                 );
        }
}
 
export default Header;
