import React, { Fragment, Component } from 'react';
import '../Legal.css';
import { Helmet } from 'react-helmet';

class Legal extends Component {
        
        componentDidMount = () =>{
                this.props.photo(7);
               window.scrollTo(0,0);
               document.getElementById('presentation').setAttribute('style','display:none;');
               document.getElementById('Facades').setAttribute('style','display:none;');
               document.getElementById('Metal').setAttribute('style','display:none;');
               document.getElementById('References').setAttribute('style','display:none;');
               document.getElementById('Contact').setAttribute('style','display:none;');
               document.getElementById('Batiments').setAttribute('style','display:none;');
               document.getElementById('Legalo').setAttribute('style','display:block;');
               document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg');");
               if(document.getElementById('padNavig').style.display === 'block'){
                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg");');
        }
        }

        render() { 
                return ( 
                        <Fragment>
                                 <Helmet>
                                        <title>SFM, Nos Mentions Légales et Politique de Confidentialité</title>
                                        <meta name="description"content="Notre société SFM est spécialisée dans la conception, la fabrication, le montage et la pose de vos menuiseries, miroiteries et de vos ouvrages métalliques sur mesure."/>
                                </Helmet>
                                <div id="lois">
                                        <div id="mention">
                                                <p><b>Nom ou Raison sociale :</b> Serrureries Fermetures Miroiteries</p>
                                                <p><b>N° SIRET :</b> 44819713700046</p>
                                                <p><b>Adresse :</b> 105 av Victor Schoelcher</p>
                                                <p><b>Code postal :</b> 91170</p>
                                                <p><b>Ville :</b> Viry Chatillon</p>
                                                <p><b>Téléphone :</b> 0169240381</p>
                                                <p><b>Adresse électronique :</b> contact@sfm-serrurerie.fr</p>
                                                <p><b>Directeur de la rédaction et de publication Serrureries Fermetures Miroiteries :</b> Djema Ludovic</p>
                                                <br/>
                                                <p>L'hébergement est assuré par IONOS by 1&1.</p>
                                        </div>
                                        <section>
                                                <h2>Protection de la vie privée</h2>
                                                <p>Les données personnelles communiquées sont nécessaires aux fins de vous contacter. Elles sont destinées à Serrureries Fermetures Miroiteries (ci-après « l’Annonceur ») et ses sous-traitants. Vous disposez de droits d’accès, de rectification, d’effacement, de portabilité, de limitation, d’opposition, de retrait de votre consentement à tout moment et du droit d’introduire une réclamation auprès d’une autorité de contrôle, ainsi que d’organiser le sort de vos données post-mortem. Vous pouvez exercer ces droits par voie postale à l'adresse 105 av Victor Schoelcher, ou par courrier électronique à l'adresse sfm-serrurerie[a]wanadoo.fr. Un justificatif d'identité pourra vous être demandé. Nous conservons vos données pendant la période de prise de contact puis pendant la durée de prescription légale aux fins probatoire et de gestion des contentieux.</p>
                                                <p>Nous nous réservons le droit de modifier à tout moment la présente page. Nous vous invitons par conséquent à la consulter régulièrement afin de vous informer de ses évolutions.</p>
                                                <p>Dernière date de Mise à jour : 18 Février 2021</p>
                                        </section>
                                        <section>
                                                <h2>1. Identité du responsable du traitement</h2>
                                                <p>Les données personnelles sont collectées par Serrureries Fermetures Miroiteries, l’Annonceur du site tel qu’identifié dans les mentions légales.</p>
                                        </section>
                                        <section>
                                                <h2>2. Les traitements relatifs à vos données personnelles</h2>
                                                <p>Notre site ne demande pas d'enregistrement nominatif à ses visiteurs et ne procède à aucun enregistrement nominatif pour la simple consultation de ses pages.</p>
                                                <p>Cependant, dans certains cas (formulaire de contact, fonctionnalités de partage et modules sociaux, rubrique création de compte, paiement en ligne...), vous pouvez être invité à laisser des données personnelles (noms, prénoms, numéros de téléphones, adresses postales, adresse électronique…). Le caractère obligatoire ou facultatif des données vous est signalé lors de la collecte par un astérisque.</p>
                                                <p>Le cas échéant, le formulaire de collecte pourra être accompagné d'une case à cocher vous permettant d'accepter ou de refuser que vos données soient utilisées à des fins commerciales pour le compte de tiers, et/ou cédées à des tiers.</p>
                                                <p>Par ailleurs, lors de la consultation de notre site web et de l’utilisation de nos services, nous sommes amenés à collecter et traiter des données relatives à votre navigation (notamment les cookies, et les pages que vous avez consultées), et à votre terminal (type de navigateur utilisé, modèle et version de votre système d’exploitation, résolution de votre écran, présence de certains plug-ins, …). Ces données seront utilisées d’une part pour adapter nos contenus et services à vos besoins, et d’autre part pour réaliser des statistiques d’utilisation de nos services afin de les rendre plus pertinents.</p>
                                                <p>Avec votre consentement, nous réaliserons également une géolocalisation approximative de votre ville d’origine à partir de votre adresse IP.</p>
                                        </section>
                                        <section>
                                                <h2>Quand ?</h2>
                                                <p>Nous collectons vos données notamment quand :</p>
                                                <ul>
                                                        <li>Vous créez et utilisez un compte personnel,</li>
                                                        <li>Vous utilisez notre service (par exemple à l’occasion d’une transaction en ligne, d’une prise de rdv…),</li>
                                                        <li>Vous utilisez notre formulaire de contact,</li>
                                                        <li>Vous naviguez sur notre site et consultez nos produits et services.</li>
                                                </ul>
                                        </section>
                                        <section>
                                                <h2>Quelles finalités ?</h2>
                                                <p>Nous utilisons vos données personnelles pour :</p>
                                                <ul>
                                                        <li>vous permettre d’utiliser nos services;</li>
                                                        <li>vous contacter pour répondre à vos demandes;</li>
                                                        <li>Nous permettre d’établir des statistiques anonymes de comptage et d’audience strictement nécessaires à la délivrance des services;</li>
                                                        <li>Assurer la sécurité du site;</li>
                                                        <li>vous faire bénéficier de nos produits et services</li>
                                                        <li>vous informer sur les services pour lesquels vous avez exprimé un intérêt et qui vous sont proposés sur notre Site;</li>
                                                        <li>permettre à nos partenaires de vous proposer des offres publicitaires en dehors de notre site en rapport avec vos centres d'intérêt;</li>
                                                        <li>vous inscrire à une newsletter et vous envoyer des informations relatives à nos produits et services;</li>
                                                        <li>nous permettre de traiter les demandes d’exercice de droits ;</li>
                                                        <li>nous permettre de répondre aux demandes officielles d’autorités publiques ou judiciaires habilitées à cet effet</li>
                                                </ul>
                                        </section>
                                        <section>
                                                <h2>Cookies</h2>
                                                <p>Les cookies sont des petits fichiers textes, stockés sur votre navigateur lorsque vous consultez notre site et qui vont être utilisés pour reconnaître votre terminal lorsque vous vous connectez à notre site ou à l’un de nos services.</p>
                                                <h3>Cookies strictement nécessaires à l'utilisation du service demandé</h3>
                                                <p>Ils permettent l'utilisation des principales fonctionnalités du site, comme le cas échéant l'accès à votre compte personnel, ou encore de mémoriser les préférences d'affichage de votre terminal (langue, paramètres d'affichage) et d'en tenir compte lors de vos visites, selon la charte graphique et les logiciels de visualisation ou de lecture de votre terminal. Ils nous permettent aussi de lier entre elles les différentes pages consultées pour vous assurer une navigation fluide.</p>
                                                <h3>Cookies de mesure d’audience et de statistiques</h3>
                                                <p>Ils nous permettent de faire vivre le site et d’établir des statistiques et comptages de fréquentation et d’utilisation de ses rubriques et contenus, pour réaliser des études afin d’améliorer le contenu (mesure du nombre de visites, de pages vues ou encore de l’activité des visiteurs sur le site et de leur fréquence de retour). Ils nous permettent également d’analyser la navigation des internautes afin d’améliorer notre service ou de détecter des dysfonctionnements.</p>
                                                <h3>Cookies publicitaires</h3>
                                                <p>Ils sont susceptibles d'être placés dans votre terminal, afin d'identifier vos centres d'intérêt au travers des produits consultés sur notre site et de collecter des données de navigation afin de personnaliser l'offre publicitaire qui vous est adressée en dehors de notre site, par nos partenaires.</p>
                                                <h3>Cookies liés à la lecture de vidéos</h3>
                                                <p>Pour le visionnage de vidéos, nous proposons sur ce site un lecteur dont l'utilisation entraîne le dépôt de cookies tiers par nos partenaires.</p>
                                        </section>
                                        <section>
                                                <h2>Destinataires</h2>
                                                <p>Sont destinataires des données :</p>
                                                <ul>
                                                        <li>l’Annonceur et ses éventuels sous-traitants,</li>
                                                        <li>Les sociétés fournissant les modules tiers implémentés le cas échéant sur ce site (ex : prise de rendez-vous, alerte email, lecture vidéo…),</li>
                                                </ul>
                                                <p>Toute transmission des données à des sociétés tierces est soumise à votre consentement préalable.</p>
                                                <p>Nous proposons sur ce site certaines fonctionnalités dont l’utilisation implique un transfert de données hors Union Européenne, aux Etats-Unis. Ce transfert est encadré par la signature de clauses contractuelles types avec les sociétés concernées.</p>
                                        </section>
                                        <section>
                                                <h2>Durée de conservation</h2>
                                                <p>Vos données de contact sont conservées pendant la période de prise de contact ainsi que pendant la durée nécessaire à des fins probatoires et de gestion des contentieux.</p>
                                                <p>Les données relatives aux commandes et aux paiements sont conservées pendant la période nécessaire à l’exécution de la transaction ainsi que pendant la durée nécessaire à des fins probatoire et de gestion des contentieux.</p>
                                                <p>Vos données de navigation sont conservées pendant la durée strictement nécessaire à la finalité du traitement considéré, ainsi :</p>
                                                <p>- Les informations collectées par le biais de ces cookies et autres traceurs avec votre consentement sont conservées pour une durée ne pouvant pas excéder 14 mois.</p>
                                                <p>- s’agissant des informations collectées par le biais de cookies et autres traceurs à des fins de mesure d’audience et analytiques et considérés comme essentiels au fonctionnement du service, elles sont conservées pour une durée ne pouvant pas excéder 25 mois.</p>
                                        </section>
                                        <section>
                                                <h2>Vos droits</h2>
                                                <p>Vous disposez de droits d’accès, de rectification, d’effacement, d’opposition au ou de limitation du traitement de vos données, de retrait de votre consentement.</p>
                                                <p>Vous pouvez enfin donner des directives concernant l’utilisation de vos données après votre décès.</p>
                                                <p>Vous pouvez exercer ces droits auprès de l’Annonceur en utilisant les coordonnées indiquées ci-dessus ou à défaut dans les mentions légales.</p>
                                                <p>Un justificatif d’identité pourra vous être demandé.</p>
                                                <p>Vous avez également le droit d’introduire une réclamation à notre rencontre auprès de l’autorité de contrôle.</p>
                                        </section>
                                </div>
                        </Fragment>
                 );
        }
}
 
export default Legal;