import React, { Fragment, Component } from 'react';
import '../Facades.css';
import { Helmet } from 'react-helmet';

class Facades extends Component {
      
        componentDidMount = () =>{
                this.props.photo(3);
                document.getElementById('Metal').setAttribute('style','display:none;');
                document.getElementById('presentation').setAttribute('style','display:none;');
                document.getElementById('Batiments').setAttribute('style','display:none;');
                document.getElementById('References').setAttribute('style','display:none;');
                document.getElementById('Contact').setAttribute('style','display:none;');
                document.getElementById('Legalo').setAttribute('style','display:none;');
                document.getElementById('Facades').setAttribute('style','display:block;');
                document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/IMG_20210117_161631.jpg');");
                if(document.getElementById('padNavig').style.display === 'block'){
                        document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/IMG_20210117_161631.jpg");');
                }
        }

        render() { 
                return ( 
                        <Fragment>
                                 <Helmet>
                                        <title>SFM, vos façades de magasin sur mesure, à Viry-Châtillon et Brie-Comte-Robert</title>
                                        <meta name="description"content="Depuis plus de 30 ans, toute l’équipe de notre société SFM est à votre disposition pour concevoir, fabriquer et installer vos façades et vos devantures de magasin."/>
                                </Helmet>
                                <div id="magasins">
                                        <p>Depuis plus de 30 ans, toute l’équipe de notre société SFM est à votre disposition pour <b>concevoir, fabriquer et installer vos façades et vos devantures de magasin</b>.</p>
                                        <p>Nous nous déplaçons pour étudier préalablement vos besoins et votre budget : nous réalisons des plans personnalisés et vous délivrons des devis gratuits et sans engagement.</p>
                                        <p>Tous nos produits portent la <b>garantie décennale</b> ; ils sont conformes aux normes françaises et européennes. Nous nous déplaçons sur toute la région Île-de-France.</p>
                                        <p>Besoin de plus de sécurité ? Nous vous proposons également un large choix de <span>serrures et de portes extérieures</span>. Un problème sur vos installations ? Notre <b>service après-vente</b>  est à votre disposition en permanence.</p>
                                </div>
                                <section>
                                        <h2>Comment bien choisir son matériau ?</h2>
                                        <div id="travaillons">
                                                <div>
                                                        <p>Nous travaillons aussi bien pour les professionnels (collectivités locales, bâtiments administratifs…) que pour les particuliers.</p>
                                                        <p>Nous réalisons des travaux <b>en neuf comme en rénovation</b>.</p>
                                                        <p>Toutes nos menuiseries se déclinent en aluminium, en bois, en PVC ou en mixte ; elles sont pensées pour vous permettre de <b>réaliser des économies d’énergie</b>.</p>
                                                        <p>Nous mettons l’accent sur les propriétés isolantes (thermique et acoustique) de ces dernières. Elles sont parfaitement étanches.</p>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                        <div id="allu">
                                                <br/>
                                                <h3>L'aluminium</h3>
                                                <p>Matériau recyclable à plus de 99 %, l’aluminium possède les mêmes avantages que le PVC mais est beaucoup plus maniable.</p>
                                                <p>Il est particulièrement <b>adapté aux fenêtres et dormants des baies vitrées coulissantes de grande taille</b> : ses montants très fins vous apportent une luminosité optimale.</p>
                                                <p>Il est également disponible en plusieurs coloris (peinture thermolaquée).</p>
                                                <br/>
                                        </div>
                                        <div id="bois">
                                                <br/>
                                                <h3>Le bois</h3>
                                                <p>Plus traditionnel, le bois est le matériau noble par excellence : traité au moyen de produits écologiques, il est présenté sous différentes essences (chêne, Moabi, pin, Mélèze…).</p>
                                                <p>Il est hydrofuge, fongicide et insecticide.</p>
                                                <p>Il est <b>durable, esthétique et très isolant</b>.</p>
                                                <p>Il nécessite un entretien régulier (lasure, peinture).</p>
                                                <br/>
                                        </div>
                                </section>
                                <section>
                                        <h2>Nos fenêtres et portes-fenêtres</h2>
                                        <div id="windows">
                                                <div>
                                                        <p>Il existe plusieurs systèmes d’ouverture de fenêtres :</p>
                                                        <p>pour vous conseiller au mieux, notre équipe tient compte de la configuration de votre magasin et de l’agencement de vos pièces.</p>
                                                </div>
                                                <div>
                                                        <ul>
                                                                <li>Nos fenêtres fixes ne s’ouvrent pas ; elles apportent une source de lumière supplémentaire,</li>
                                                                <li>Nos fenêtres à la française, composées de un ou deux battants, s’ouvrent vers l’intérieur</li>
                                                                <li>Nos fenêtres oscillo-battantes s’ouvrent sur un axe vertical ou horizontal. Elles ne comportent qu’un seul battant. Elles sont pratiques et particulièrement sécurisantes ; elles peuvent s’ouvrir sur un petit espace ou en grand</li>
                                                                <li>Nos fenêtres basculantes sont utilisées principalement pour les fenêtres de toits ou les façades</li>
                                                                <li>Nos fenêtres à soufflet s’ouvrent vers l’intérieur mais seulement sur une petite ouverture</li>
                                                        </ul>
                                                        <br/>
                                                </div>
                                        </div>
                                        <div id="baie">
                                                <div>
                                                        <h3>Nos baies vitrées coulissantes</h3>
                                                        <p>Nos baies vitrées coulissantes sont adaptées aux grandes surfaces ; leurs deux vantaux glissent l’un sur l’autre pour ouvrir toute la surface du mur si besoin.</p>
                                                        <p><b>À galandage</b>, elles se coulissent dans le mur et vous font gagner de l’espace.</p>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                </section>
                                <section>
                                        <h2>Notre gamme de miroiterie</h2>
                                        <div id="miroir">
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                                <div>
                                                        <p>Alliant isolation thermique, phonique et acoustique, nos vitres et nos vitrines peuvent être à simple, double ou triple vitrage.</p>
                                                        <p>Nous vous proposons plusieurs types de vitrages :</p>
                                                        <ul>
                                                                <li><b>Vitrage feuilleté</b> : composé de plusieurs feuilles de verres posées et collées les unes sur les autres, ce vitrage est particulièrement résistant aux chocs et donc aux tentatives d’effraction</li>
                                                                <li><b>Vitrage trempé</b> : il est très résistant aux écarts de température et aux chocs thermiques. Il supporte une température de plus de 250 degrés. En cas de casse, il se brise en très petits éclats, ce qui limite le risque de blessure</li>
                                                                <li><b>Vitrage armé</b> : il est renforcé par une grille métallique intégré dans le vitrage. Il est surtout utilisé pour les toitures, notamment pour la conception de jardins d’hiver</li>
                                                        </ul>
                                                        <p>Nous sommes également en mesure de réaliser des verrières à l’ancienne.</p>
                                                        <p>Besoin d’installer une vitrine pour mettre en valeur les produits de votre commerce ?</p>
                                                </div>
                                        </div>
                                </section>
                                <section>
                                        <h2>Nos volets roulants et nos stores</h2>
                                        <div id="volets">
                                                <div>
                                                        <p>Vous souhaitez harmoniser la façade de votre magasin en choisissant des volets et des stores adaptés à vos <b>fenêtres</b>, vos portes-fenêtres et vos baies vitrées ?</p>
                                                        <p>Nos volets roulants et nos stores peuvent être à <b>ouverture manuelle</b> (à sangle ou à manivelle) <b>ou motorisée</b>.</p>
                                                        <p>Ils sont faciles à manœuvrer et vous protègent des regards extérieurs ; ils vous permettent de moduler facilement la lumière, au moyen de leurs lames orientables.</p>
                                                        <p>Motorisés, ils s’ouvrent et se ferment sans avoir besoin d’ouvrir les fenêtres. Ils sont sécurisants car équipés d’un <b>système anti-arrachement</b> ou anti-crochetage.</p>
                                                        <p>Nous vous proposons également des modèles de <b>stores bannes</b> et <b>d’auvents</b>, déclinables en plusieurs coloris.</p>
                                                </div>
                                                <div>
                                                        <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                                </div>
                                        </div>
                                </section>
                                <section>
                                        <h2>Nos rideaux métalliques et nos grilles</h2>
                                        <div id="rido">
                                                <br/>
                                                <p>Vous souhaitez sécuriser davantage vos magasins ?</p>
                                                <p>Notre société SFM vous propose également des grilles et des rideaux métalliques, qui protègent vos magasins contre les cambriolages, le vandalisme ou les tentatives d’effraction.</p>
                                                <p>Notre société SFM vous propose également des grilles et des rideaux métalliques, qui protègent vos magasins contre les cambriolages, le vandalisme ou les tentatives d’effraction.</p>
                                                <ul>
                                                        <li><b>À lames pleines</b>, ils cachent entièrement la façade de votre commerce ; vous êtes protégé intégralement</li>
                                                        <li><b>À lames dentelles ou perforées</b>, ils laissent <span>votre vitrine</span> plus visible et permettent aux passants de visualiser les produits présentés dans le magasin</li>
                                                </ul>
                                                <br/>
                                        </div>
                                </section>
                        </Fragment>
                 );
        }

}
 
export default Facades;