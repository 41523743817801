import React, { Fragment } from 'react';
import{BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

const Footer = () => {
        return ( 
                <Fragment>
                        <footer>
                                <div id="foot">
                                        <div>
                                                <p className="noFooter">SFM</p>
                                                <p>Siège social :</p>
                                                <ul>
                                                        <li>105/117 avenue Victor Schoelcher 91170 Viry-Châtillon</li>
                                                        <li>Tél : 01 69 24 03 81</li>
                                                        <li>Fax : 01 69 24 03 29</li>
                                                </ul>
                                                <p>Notre autre antenne :</p>
                                                <ul>
                                                        <li>45 rue Général Leclerc 77170 Brie Comte Robert</li>
                                                        <li>Tél : 01 60 29 60 22</li>
                                                </ul>
                                        </div>
                                        <div>
                                                <p id="no">&nbsp;</p>
                                               <p>Zone d’intervention :</p>
                                               <p className="noFooter">Viry-Châtillon, Brie-Comte-Robert,</p>
                                               <p className="noFooter">Seine-et-Marne, Essonne, Ile-de-France</p>
                                               <p>Horaires d’ouverture :</p>
                                               <p className="noFooter">24h/24 et 7j/7</p>
                                        </div>
                                </div>
                                <br/>
                                <hr/>
                                <div id="ms"><span>Ce site a été réalisé par</span> <a href="https://www.matthieu-sibert.ltd/" target="blank" hrefLang="fr" rel="external">Matthieu SIBERT</a> - <Link href="#Legal" to="/Mentions-légales"  hrefLang="fr" rel="licence">Mentions légales</Link></div>
                        </footer>
                </Fragment>
         );
}
 
export default Footer;