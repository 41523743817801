import React, { Fragment,Component } from 'react';
import { Helmet } from 'react-helmet';
import '../Acceuil.css';

class Acceuil extends Component {
        
        componentDidMount = () =>{
                        this.props.photo(1);
                        document.getElementById('Metal').setAttribute('style','display:none;');
                        document.getElementById('Facades').setAttribute('style','display:none;');
                        document.getElementById('Batiments').setAttribute('style','display:none;');
                        document.getElementById('References').setAttribute('style','display:none;');
                        document.getElementById('Contact').setAttribute('style','display:none;');
                        document.getElementById('Legalo').setAttribute('style','display:none;');
                        document.getElementById('presentation').setAttribute('style','display:block;');
                        document.getElementsByTagName('header')[0].setAttribute('style',"background-image: url('./images/eyeemfiltered1579424914184.jpg');");   
                        if(document.getElementById('padNavig').style.display === 'block'){
                                document.getElementsByTagName('header')[0].setAttribute('style','height:900px !important;background-image: url("./images/eyeemfiltered1579424914184.jpg");');
                        }
                 }

        render() { 
                return (
                        <Fragment>
                                <Helmet>
                                        <title>SFM, Serrurerie, Menuiserie et Miroiterie, à Viry-Châtillon et Brie-Comte-Robert</title>
                                        <meta name="description"content="Depuis plus de 30 ans, toute l’équipe de notre société SFM est à votre disposition pour concevoir, fabriquer et installer vos façades et vos devantures de magasin. Nous nous déplaçons pour étudier préalablement vos besoins et votre budget : nous réalisons des plans personnalisés et vous délivrons des devis gratuits et sans engagement. Tous nos produits portent la garantie décennale ; ils sont conformes aux normes françaises et européennes. Nous nous déplaçons sur toute la région Île-de-France. Besoin de plus de sécurité ? Nous vous proposons également un large choix de serrures et de portes extérieures. Un problème sur vos installations ? Notre service après-vente est à votre disposition en permanence."/>
                                </Helmet>
                        <h1>
                                SFM, serrurerie, menuiserie et miroiterie, à Viry-Châtillon et Brie-Comte-Robert
                        </h1>
                        <section>
                                <h2>Une équipe qualifiée à votre service</h2>
                                <div id="exp">
                                        <div>
                                                <p>Notre équipe est forte de plus de <b>trente ans d’expérience</b>.</p>
                                                <p>Elle est composée de sept collaborateurs ; elle est disponible et compétente.</p>
                                                <p>Travaillant autant pour les particuliers que les professionnels, nous intervenons dans la <b>région Île-de-France</b>, notamment dans les départements de la Seine-et-Marne et de l’Essonne.</p>
                                                <p>Nous sommes susceptibles de travailler en partenariat avec les collectivités locales, les administrations, les commerces ou les entreprises du bâtiment.</p>
                                                <p>Nous nous déplaçons sur place pour une étude préalable de vos besoins et de votre budget.</p>
                                                <p>Un problème avec vos produits ? Notre service après-vente est à votre disposition.</p>
                                        </div>
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                </div>
                                <div id="plusengage">
                                        <article>
                                                <br/> 
                                                <h3>Nos plus</h3>
                                                <br/> 
                                                <ul>
                                                        <li>Réactivité</li>
                                                        <li><b>Rapidité</b></li>
                                                        <li>Écoute</li>
                                                        <li><b>Professionnalisme</b></li>
                                                        <li>Proximité</li>
                                                </ul>
                                        </article>
                                        <br/> 
                                        <article>
                                                <h3>Nos engagements</h3>
                                                <br/> 
                                                <ul>
                                                        <li><b>Respect des délais</b></li>
                                                        <li>Respect des normes en vigueur</li>
                                                        <li>Matériel professionnel performant</li>
                                                        <li>Connaissance parfaite des dossiers</li>
                                                        <li><b>Interlocuteur unique</b></li>
                                                </ul>
                                                <br/> 
                                        </article>
                                </div>
                        </section>
                        <section>
                                <h2>Nos produits</h2>
                                <div id="mesure">
                                        <p>Nous concevons, fabriquons et installons des équipements métalliques sur-mesure :</p>
                                        <p>escaliers hélicoïdaux, passerelles.</p>
                                        <p>Nous rénovons également vos façades de magasin.</p>
                                </div>
                                <div id="fournisseurs">
                                <br/> 
                                        <p>Nos fournisseurs sont sélectionnés parmi les meilleurs pour la qualité de leurs produits et leur fiabilité.</p>
                                        <p>Tous nos produits portent la <b>garantie décennale</b>.</p>
                                        <p>Nous réalisons les <b>ouvertures et les fermetures de vos bâtiments</b>, commerces, copropriétés ou maisons individuelles.</p>
                                        <br/> 
                                </div>
                                <div id="produits">
                                <br/> 
                                        <p><b>Nous vous proposons une large gamme de produits, en aluminium, PVC ou bois :</b></p>
                                        <ul>
                                                <li>fenêtres,</li>
                                                <li>portes extérieures,</li>
                                                <li>vitrerie (vitrine de magasin, double vitrage, vitrage feuilleté…),</li>
                                                <li>volets roulants,</li>
                                                <li>rideaux métalliques,</li>
                                                <li>grilles….</li>
                                        </ul>
                                        <br/> 
                                </div>
                        </section>
                        <section>
                                <h2>Dépannage de votre serrurerie 24h/24 et 7j/7 en Ile de France</h2>
                                <div id="h24">
                                <br/> 
                                        <p>Quels que soient le jour ou l’heure, nous intervenons chez vous en cas de problème de <b>serrurerie</b>.</p>
                                        <p>Notre entreprise fonctionne tous les jours de l’année, dimanches et jours fériés compris. Nous avons accès à plus de 10 000 marques de serrures.</p>
                                        <p style={{textAlign:'center'}}><b>Nos services :</b></p>
                                        <ul>
                                                <li>remplacement de serrure,</li>
                                                <li>ouverture de porte à la suite d’un vol ou de la perte de vos clés,</li>
                                                <li>mise en sécurité ou fermeture provisoire.</li>
                                        </ul>
                                        <br/> 
                                        <br/> 
                                </div>
                                <div id="depan">
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                        <div>
                                                <img src={process.env.PUBLIC_URL+'/images/sfm-serrurerie-fermetures-miroiterie-viry-chatillon-5e4a2351931fa.jpg'} alt="Logo de l'entreprise"/>
                                        </div>
                                </div>
                               
                        </section>
                </Fragment>
                 );
        }
}
 
export default Acceuil;
